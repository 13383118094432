import React, { useState, useEffect } from "react"
// import axios from "axios"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import { StripeProvider } from "react-stripe-elements"
import { Stripe } from "stripe.js"
import Checkout from "../../components/checkout"

import BackendCourseBackground from "../../pics/backend-gif.gif"
import BackendCourseOverlay from "../../pics/express-banner-overlay.png"
import Instalments from '../../components/instalments.js'

import Contacts from "../../components/contactform"
import Covid from "../../components/covid"

const BackendcrashcourseOnline = () => {
	return (
		<div className="flexContent">
		<Helmet
		title="Back-end Development with Node, Express, MongoDB"
		meta={[
			{
				name: "description",
				content:
				"Back-end Development with Node, Express, MongoDB Online Course in Barcelona Code School is a 2-week long intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners familiar with JavaScript. This course is a part of the JavaScript Full-Stack Bootcamp and could be taken separately as a module.",
			},
			{
				name: "keywords",
				content:
				"programming with react course, react course, web development with react in barcelona",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			{
				property: "og:title",
				content:
				"Back-end Development with Node, Express, MongoDB Course in Barcelona Code School",
			},
			{
				property: "og:description",
				content:
				"A 2-week intensive programming course from Barcelona Code School. Fast-paced practical training aimed at beginners familiar with JavaScript . This course is a part of the JavaScript Full-Stack Bootcamp and could be taken separately as a module.",
			},
			{
				property: "og:image",
				content:
				"https://barcelonacodeschool.com/static/backend-gif-682b253b8d38a9654cf4f906d2158130.gif",
			},
			{
				property: "og:url",
				content:
				"https://barcelonacodeschool.com/backend-development-with-node-express-mongodb/",
			},
			{ name: "twitter:card", content: "summary_large_image" },
			]}
			script={[{ src: "https://js.stripe.com/v3/" }]}
			/>

			<h1 className='transparentContainer flex1200'>Back-end Development with Node, Express, MongoDB{" "}
			<span className="highlighter">Online</span> course</h1>

			<div
			className="fulltimeOnlineBanner flex1200"
			style={{ position: "relative" }}
			>
			<p className="stamp">ONLINE</p>
			<img
			className="banners offsetImage"
			style={{ width: "99%" }}
			src={BackendCourseBackground}
			alt="Back-end Development with Node, Express, MongoDB Course in Barcelona Code School"
			/>
			<img
			className="banners"
			style={{
				position: "absolute",
				bottom: "10px",
				left: "5px",
			}}
			src={BackendCourseOverlay}
			alt="Back-end Development with Node, Express, MongoDB Course in Barcelona Code School"
			/>
			</div>



			<h2 className='transparentContainer flex450'>Learn how to build servers and work with a database!</h2>

			<h2 className='transparentContainer flex225'>2 weeks, part-time, online, in English</h2>





			<div className="socialsharebuttons">
			<div className="socialshare">
			<a
			className="twitter-share-button "
			href="https://twitter.com/intent/tweet?text='Back-end Development with Node, Express, MongoDB Course in Barcelona Code School'&url=https://barcelonacodeschool.com/online-short-courses/backend-development-with-node-express-mongodb-online/"
			data-size="large"
			>
			<button>tweet</button>
			</a>
			</div>

			<div
			className="fb-share-button socialshare"
			data-href="https://developers.facebook.com/docs/plugins/"
			data-layout="button"
			data-size="small"
			>
			<a
			target="_blank"
			href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/online-short-courses/backend-development-with-node-express-mongodb-online/"
			className="fb-xfbml-parse-ignore"
			>
			<button>facebook</button>
			</a>
			</div>
			</div>



			<article className='whiteCard flex300'>
			<ul>
			<li>Part-time hands-on mentored training</li>
			<li>2 weeks of back-end coding</li>
			<li>2 personal 1:1 sessions</li>
			<li>4.5 hours of video lessons</li>
			<li>7 server exercises</li>
			<li>
			Support platform for code-related questions available
			24/7
			</li>
			<li>The course is taught in English</li>
			<li>From zero to 👸 and 🤴</li>
			<li>15% theory / 85% practice / 100% efficiency</li>
			</ul>
			</article>
			

			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul>
			<li>Overall concepts of back-end development</li>
			<li>Set up</li>
			<li>Exporting and importing</li>
			<li>Working with npm</li>
			<li>Structuring your server</li>
			<li>Routes and controllers</li>
			<li>Passing data via params and body</li>
			<li>HTTP requests</li>
			<li>Serving static content</li>
			<li>HTTP Status Codes</li>
			<li>Using Postman for a development</li>
			<li>Persistent storage with MongoDB</li>
			<li>Runing MongoDB locally and remotely</li>
			<li>Mongoose</li>
			<li>Handling errors</li>
			<li>Deployment</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<p>
			Solid understanding of JavaScript logic and syntax;
			functions, loops, conditionals, arrays, objects.
			</p>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<p>
			After this course you will have a strong understanding of
			the <strong>logic</strong>, <strong>syntax</strong> and{" "}
			<strong>usage</strong> of Express and back-ends in general.{" "}
			</p>
			<p>
			You will be able to build Express servers from scratch and
			have progress further into the depths of back-end
			development.
			</p>
			</div>
			</article>


			<article className='transparentContainer flex225'>
			<h2>A Wise quote</h2>
			<div className='whiteCard'>
			<p>"Only half of programming is coding. The other 90% is debugging." </p>
			<p>– Unknown</p>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>750€</h2>
			<p>100% is paid upon registration.</p>
			<Instalments/>
			</div>
			</article>

<article className="transparentContainer flex300">
			<h2>How it works</h2>
			<div className='whiteCard'>
			<ul>
			<li>Sign up through this page</li>
			<li>Fill out student's form we will send you</li>
			<li>
			Get the materials including texts, videos and exercises with
			testing platform
			</li>
			<li>Start learning</li>
			<li>
			Access support platform 24/7 to post code-related questions
			and get help
			</li>
			<li>Book your 1:1 sessions from our calender</li>
			<li>
			Go through the lectures and complete exercises for each
			block of the curriculum
			</li>
			<li>Push your code to GitLab to get feedback/review from us</li>
			<li>Get yourt certificate once finished</li>
			</ul>
			</div>
			</article>

<article className="transparentContainer flex900 flexContent">
			<h2 id="dates">Access</h2>
			<div>
			<div className="whiteCard">
			<span>
			<h2>
			Back-end Development with Node, Express, MongoDB{" "}
			<span className="highlighter">Online</span> course
			</h2>
			<p>Mentored, part-time, 2 weeks </p>
			</span>
			<span>
			<Link
			to="/register"
			state={{
				course:
				"Back-end Development with Node, Express, MongoDB",
				date: new Date().toLocaleDateString(),
				cost: 750,
				images: [
				"https://barcelonacodeschool.com/files/pics/backend-gif.gif",
				],
			}}
			>
			<button style={{ marginBottom: "1em" }}>
			Sign up
			</button>
			</Link>
			<p className="finePrint">
			By signing up you agree to the{" "}
			<a href="/about-us/terms-and-conditions/">
			Terms and Conditions
			</a>{" "}
			stated on the website.
			</p>
			</span>
			</div>
			</div>
			</article>

<article className='flex450'>
			<Contacts
			title={"Inquiry about the course"}
			subject={
				"Inquiry about Back-end Development with Node, Express, MongoDB Online"
			}
			/>
			</article>

			
			</div>
			)
}

export default BackendcrashcourseOnline
